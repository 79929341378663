<template>
	<SectionHeader :image="null" title="Verein" page="Mitglied werden" />
	<div class="container">
		<div class="alert alert-info" role="alert">Bisher ist kein Inhalt hinterlegt</div>
	</div>
</template>
<script>
import SectionHeader from '@/views/SectionHeader.vue'
export default {
	name: 'Beitritt',
	components: {
		SectionHeader
	},
	mounted () {
		window.scrollTo(0, 0)
	}
}
</script>
